import React, { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg">
        <div className="container">
          <div className="navbar-light bg-darks">
            {/* Logo */}
            <a className="navbar-brand" href="index.html">
              <img src="images/head_logo.png" alt="" />
            </a>
            {/* Logo */}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={ToggleSidebar}
            >
              {isOpen === true ? (
                <h2>x</h2>
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </button>
            <div
              className={
                `sidebar ${isOpen === true ? "active" : ""}` +
                "collapse navbar-collapse"
              }
              id="navbarText"
            >
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/life">
                    Life
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/health">
                    Health
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/car">
                    Car
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/bike">
                    Bike
                  </NavLink>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown_1"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    id="navbarDropdown"
                  >
                    <a className="dropdown-item" href="car_claim.html">
                      Car Claim
                    </a>
                    <a className="dropdown-item" href="bike_claim.html">
                      Bike Claim
                    </a>
                    <a className="dropdown-item" href="policy.html">
                      My Policies
                    </a>
                    <a className="dropdown-item" href="form.html">
                      Form - 1
                    </a>
                    <a className="dropdown-item" href="form_buy.html">
                      Form - 2
                    </a>
                  </div>
                </li> */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0">
                <div className="btn-group user">
                  <Link to="/login">
                    <button
                      type="button"
                      className="btn-link dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src="images/user.png" alt="" />
                    </button>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button className="dropdown-item" type="button">
                      Profile
                    </button>
                    <button className="dropdown-item" type="button">
                      My Policy
                    </button>
                    <button className="dropdown-item" type="button">
                      Logout
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </nav>
      <Outlet />
    </>
  );
};

export default Header;
