import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";

const Login = () => {
  return (
    <>
      <Breadcrumb subtitle="User Login" title="Login" bg="hero-small-car" />
      <div className="container">
        <div className="row">
          <div className="col-4 mx-auto mb-5">
            <div class="send-quotes bg-color-2">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="User Id" />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Password"
                />
              </div>
              <button type="button" className="btn btn-prim-round mt-4">
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
