import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";

const life = () => {
  return (
    <>
      <Breadcrumb
        subtitle="Get Instantly"
        title="Life Insurance"
        bg="hero-small-car"
      />
      <div className="section-first">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center p-0">
                <span className="sub-heading-1">In Simple Steps</span>
                <h2>How Bike insurance works?</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Deleniti numquam sit eligendi veritatis debitis illo
                  consequuntur tenetur distinctio, totam modi!
                </p>
              </div>
            </div>
          </div>

          <div className="row section">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="img-container">
                <img src="images/bike_insurance_1.png" alt="" />
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 offset-lg-2 text-container-right">
              <div className="container-text">
                <div className="bike-works">
                  <span>01</span>
                  <div className="works-text">
                    <h6>
                      Select your bike
                      <br /> (e.g. Honda Activa 125cc)
                    </h6>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Veritatis voluptate minus molestiae earum suscipit
                      dignissimos distinctio laboriosam corporis ab. Optio,
                      magni dolore. Libero quam mollitia aliquam nesciunt facere
                      totam aperiam.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row section">
            <div className="col-lg-5 order-lg-2 col-md-6 col-sm-12 offset-lg-2">
              <div className="img-container">
                <img src="images/bike_insurance_2.png" alt="" />
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 text-container-right">
              <div className="container-text">
                <div className="bike-works">
                  <span>02</span>
                  <div className="works-text">
                    <h6>
                      Enter the purchase date
                      <br />
                      of the bike (e.g. 2020)
                    </h6>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Veritatis voluptate minus molestiae earum suscipit
                      dignissimos distinctio laboriosam corporis ab. Optio,
                      magni dolore. Libero quam mollitia aliquam nesciunt facere
                      totam aperiam.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row section">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="img-container">
                <img src="images/bike_insurance_3.png" alt="" />
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 offset-lg-2 text-container-right">
              <div className="container-text">
                <div className="bike-works">
                  <span>03</span>
                  <div className="works-text">
                    <h6>
                      Select if your existing bike insurance has expired or not
                    </h6>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Veritatis voluptate minus molestiae earum suscipit
                      dignissimos distinctio laboriosam corporis ab. Optio,
                      magni dolore. Libero quam mollitia aliquam nesciunt facere
                      totam aperiam.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row section">
            <div className="col-lg-5 order-lg-2 col-md-6 col-sm-12 offset-lg-2">
              <div className="img-container">
                <img src="images/bike_insurance_4.png" alt="" />
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-sm-12 text-container-right">
              <div className="container-text">
                <div className="bike-works">
                  <span>04</span>
                  <div className="works-text">
                    <h6>
                      Click on 'View Prices' and you can view different plans or
                      your bike
                    </h6>
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Veritatis voluptate minus molestiae earum suscipit
                      dignissimos distinctio laboriosam corporis ab. Optio,
                      magni dolore. Libero quam mollitia aliquam nesciunt facere
                      totam aperiam.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-color-grad">
        <div className="section-large reason-sec-back">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-center">
                  <span className="sub-heading-1">6 Reasons to</span>
                  <h2>Choose our Insurance</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12 reason-sec bg-color">
          <div className="row">
            <div className="col-lg-4">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>One of the best</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur adip isicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>24/7 Support</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur adip isicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>Quick Settlement</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur adip isicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>Incredibly Low Prices</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur ad ipisicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>Low Prices</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur adip isicing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 last">
              <div className="col-icon-left-sml">
                <div className="icon-left-sml">
                  <img src="images/icon_tick_reasons.png" alt="" />
                </div>
                <div className="text-left-sml">
                  <h6>Third Party Insurance</h6>
                  <p>
                    Lorem ipsum dolor sit am etcon sectetur adip isicing elit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 text-container-left">
              <div className="container-text">
                <h2>Few easy steps to keep safe your Bike!</h2>
                <span className="sub-heading-2">
                  Bike insurance is based on the simple concept of risk sharing.
                </span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic
                  ducimus voluptatibus, et facere ab vel adipisci corporis
                  quidem. Exercitationem similique.
                </p>
                <p>
                  Voluptas provident iste nemo alias distinctio rem esse ex
                  molestiae sapiente explicabo harum quidem sunt architecto
                  nobis amet vel in optio quis cupiditate veniam, nihil
                  nesciunt, soluta labore officiis? Vero, esse nemo.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12 offset-lg-1 offset-md-1">
              <div className="img-container">
                <img
                  src="images/about_img_1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-larger bg-img-right-half">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="text-container">
                <h2>
                  <span className="sub-heading-1">Why?</span>It is Important
                </h2>
                <div className="highlight-top">
                  <h5 className="p-0">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Distinctio excepturi quis dolor? Itaque pariatur repellat
                    illo nam unde, temporibus sunt.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center">
                <span className="sub-heading-1">Three Atractive</span>
                <h2>Insurance Packages</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="package">
                <h5>Basic</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $70 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li>Damage due to fire </li>
                    <li>Naturaly damage </li>
                    <li>Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="package">
                <h5>Standard</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $101 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li className="active">Damage due to fire </li>
                    <li>Naturaly damage </li>
                    <li>Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 no-of-package mt-3">
              <div className="package">
                <h5>Extended</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $121 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li className="active">Damage due to fire </li>
                    <li className="active">Naturaly damage </li>
                    <li className="active">Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default life;
