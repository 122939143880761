import React from "react";

export const Breadcrumb = (props) => {
  return (
    <>
      <div className={`hero-image-small ${props.bg}`}>
        <div className="overlay"> </div>
        <div className="container">
          <div className="hero-text-inner">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 m-auto text-center">
                <h3>
                  <span className="sub-heading-1"> {props.subtitle}</span>
                  {props.title}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
