import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./pages/Index";
import Layout from "./Layout";
import NoPage from "./pages/NoPage";
import Contact from "./pages/Contact";
import { About } from "./pages/About";
import Bike from "./pages/Bike";
import Care from "./pages/Care";
import Login from "./pages/Login";
import Life from "./pages/Life";
import Health from "./pages/Health";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Index />} />
            <Route path="about" element={<About />} />
            <Route path="life" element={<Life />} />
            <Route path="health" element={<Health />} />
            <Route path="bike" element={<Bike />} />
            <Route path="car" element={<Care />} />
            <Route path="login" element={<Login />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
