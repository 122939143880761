import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Link } from "react-router-dom";
import { Button } from "bootstrap";

const Index = () => {
  const options = {
    loop: true,
    margin: 2,
    items: 6,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <div className="hero-image">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-text">
                {/* <h5>Renew car insurance in 2 minutes</h5> */}
                <h1 className="text-pink">
                  Renew your insurance in 2 minutes or{" "}
                  <span className="text-black">Talk to our Expert Get</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <OwlCarousel className="owl-theme slid" {...options}>
                <div className="item">
                  <div className="owl-bor">
                    <Link to="">
                      <img src="images/vdo_thumb_1.png" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="item">
                  <div className="owl-bor">
                    <Link to="">
                      <img src="images/vdo_thumb_2.png" alt="" />
                    </Link>
                  </div>
                </div>
              </OwlCarousel>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12 offset-lg-1 offset-md-1">
              <div className="form-head">
                <h6>Safeguard yourself with best Insurance </h6>
              </div>

              <div className="form-home">
                <form>
                  <div className="form-group">
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="life"
                        value="Life"
                      />
                      <label className="form-check-label" htmlFor="life">
                        Life
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="health"
                        value="health"
                      />
                      <label className="form-check-label" htmlFor="health">
                        Health
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="car"
                        value="car"
                      />
                      <label className="form-check-label" htmlFor="car">
                        Car
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="insurance"
                        id="bike"
                        value="bike"
                      />
                      <label className="form-check-label" htmlFor="bike">
                        Bike
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupExampleInput1"
                      placeholder="Enter your mobile number"
                    />
                  </div>
                  <button type="button" className="btn btn-prim-round mt-4">
                    Insure now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger bg-img-left-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="numbers">
                <h2>
                  96%
                  <span>5 Star Ratings</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste,
                  iusto!
                </p>
                <div className="number-icons">
                  <img
                    src="images/number_icon_1.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="numbers">
                <h2>
                  4.5cr
                  <span>Happy Clients</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste,
                  iusto!
                </p>
                <div className="number-icons">
                  <img
                    src="images/number_icon_2.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="numbers">
                <h2>
                  99%
                  <span>Quality Products</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste,
                  iusto!
                </p>
                <div className="number-icons">
                  <img
                    src="images/number_icon_3.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger bg-color">
        <div className="container">
          <div className="row vertical-cntr">
            <div className="col-lg-5">
              <div className="point-list">
                <ul>
                  <li>
                    <div className="list-icon">
                      <span className="flaticon-credit"></span>
                    </div>
                    <div className="list-text">
                      <h6>Competitive Premiums</h6>
                      <p>
                        Get the competitive premium for your life, health &
                        vehicle insurance
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="list-icon">
                      <span className="flaticon-car-insurance-2"></span>
                    </div>
                    <div className="list-text">
                      <h6>Convenient to track and pay premiums</h6>
                      <p>
                        Track your insurance in our app and pay your premiums on
                        time to avoid insurance getting lapsed.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="list-icon">
                      <span className="flaticon-document"></span>
                    </div>
                    <div className="list-text">
                      <h6>Hassle less Claims</h6>
                      <p>
                        Our team is here to help and guide you in your claim
                        processing.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-1">
              <div className="img-container">
                <img
                  src="images/home_img_1.png"
                  className="img-fluid mt"
                  alt=""
                />
              </div>
              <div className="text-container">
                <h2>
                  <span className="sub-heading-1">
                    Special support &amp; caring
                  </span>
                  Insurance Company
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius,
                  fugit laudantium? Ad quibusdam blanditiis quas aspernatur.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger bg-img-left">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="col-icon-bg-cntr">
                    <div className="icon-cntr">
                      <span className="flaticon-fender-bender"></span>
                    </div>
                    <div className="col-text-cntr">
                      <h6>Accidents</h6>
                      <p>Lorem ipsum, dolor sit amet conse ctetur adipi.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-6">
                  <div className="col-icon-bg-cntr">
                    <div className="icon-cntr">
                      <span className="flaticon-fire-1"></span>
                    </div>
                    <div className="col-text-cntr">
                      <h6>Fire</h6>
                      <p>Lorem ipsum, dolor sit amet conse ctetur adipi.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-6">
                  <div className="col-icon-bg-cntr">
                    <div className="icon-cntr">
                      <span className="flaticon-theft"></span>
                    </div>
                    <div className="col-text-cntr">
                      <h6>Theft</h6>
                      <p>Lorem ipsum, dolor sit amet conse ctetur adipi.</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-6">
                  <div className="col-icon-bg-cntr">
                    <div className="icon-cntr">
                      <span className="flaticon-flood"></span>
                    </div>
                    <div className="col-text-cntr">
                      <h6>Calamities</h6>
                      <p>Lorem ipsum, dolor sit amet conse ctetur adipi.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 text-container-right">
              <div className="container-text">
                <h2>What's Covered Under Our Insurance?</h2>
                <span className="sub-heading-2">
                  Specific methods and techniques have changed considerably over
                  the years
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Reiciendis cumque esse excepturi voluptate iusto soluta optio
                  autem nulla aperiam deleniti!
                </p>
                <button
                  type="Submit"
                  className="btn btn-prim-round mt-lg-5 mt-md-3 mt-sm-3"
                >
                  Read more
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 vertical-cntr">
              <div className="feedback">
                <img
                  src="images/feedback_bg.png"
                  className="img-fluid"
                  alt=""
                />
                <span className="sub-heading-1">Customer’s Feedback</span>
                <h2>Words of Appreciation</h2>

                <div className="img-container">
                  <img src="images/feedback.png" className="img-fluid" alt="" />
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Officia tempora dolor mollitia, aspernatur ratione incidunt
                  quia at fugiat dicta odio.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 offset-lg-1 offset-md-1">
              <div className="feedback-list">
                <OwlCarousel className="owl-theme slid" {...options}>
                  <div className="item">
                    <div className="owl-bor">
                      <div className="col-lg-12">
                        <div className="feedback-content-left">
                          <div className="feedback-pic-left">
                            <img src="images/pic_1.jpg" alt="" />
                          </div>
                          <div className="feedback-text-left">
                            <h6>
                              BMW Owner, NYC
                              <span>Mark Smith</span>
                            </h6>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Doloribus ea pariatur quae quis
                              reprehenderit quisquam, iusto laborum fuga!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="owl-bor">
                      <div className="col-lg-12">
                        <div className="feedback-content-left">
                          <div className="feedback-pic-left">
                            <img src="images/pic_1.jpg" alt="" />
                          </div>
                          <div className="feedback-text-left">
                            <h6>
                              BMW Owner, NYC
                              <span>Mark Smith</span>
                            </h6>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Doloribus ea pariatur quae quis
                              reprehenderit quisquam, iusto laborum fuga!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-larger bg-img-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-center">
                {/* <span className="sub-heading-1">200+ Companies</span> */}
                <h2>Connected with us</h2>
                <p>We have partnered with following Insurance Company.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 client">
              <img src="images/client_1.jpg" className="img-fluid" alt="" />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 col-6 client">
              <img src="images/client_2.jpg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="section bg-color">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 car-pb">
              <div className="new-car">
                <div className="new-veh-img">
                  <img src="images/new_car.png" className="img-fluid" alt="" />
                </div>
                <div className="new-veh-text">
                  <p className="mb-1">
                    <strong>Got new car?</strong>
                  </p>
                  <p className="mb-1">Check premiums now!</p>
                  <a href="#">Insuare</a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="new-car">
                <div className="new-veh-img">
                  <img src="images/new_bike.png" className="img-fluid" alt="" />
                </div>
                <div className="new-veh-text">
                  <p className="mb-1">
                    <strong>Bought new Bike?</strong>
                  </p>
                  <p className="mb-1">Check premiums now!</p>
                  <a href="#">Insuare</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-large bg-color-dark d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title-center">
                <span className="sub-heading-1">We Achived</span>
                <h2>Awards &amp; Recognition</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="col-img-cntr">
                <div className="img-cntr-claim">
                  <div className="img-container-sml">
                    <img src="images/awward_1.png" alt="" />
                  </div>
                </div>
                <div className="col-text-claim">
                  <h6>Best Company</h6>
                  <p>Animi illo corrupti amet fugiat quod quaerat quibusdam.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="col-img-cntr">
                <div className="img-cntr-claim">
                  <div className="img-container-sml">
                    <img src="images/awward_2.png" alt="" />
                  </div>
                </div>
                <div className="col-text-claim">
                  <h6>Great Support</h6>
                  <p>Animi illo corrupti amet fugiat quod quaerat quibusdam.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="col-img-cntr">
                <div className="img-cntr-claim">
                  <div className="img-container-sml">
                    <img src="images/awward_1.png" alt="" />
                  </div>
                </div>
                <div className="col-text-claim">
                  <h6>Growing Business</h6>
                  <p>Animi illo corrupti amet fugiat quod quaerat quibusdam.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="col-img-cntr">
                <div className="img-cntr-claim">
                  <div className="img-container-sml">
                    <img src="images/awward_2.png" alt="" />
                  </div>
                </div>
                <div className="col-text-claim">
                  <h6>Excellent Service</h6>
                  <p>Animi illo corrupti amet fugiat quod quaerat quibusdam.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-large bg-img-left-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-sm-12 m-auto">
                <div className="img-container">
                  <img src="images/bg_newsletter.png" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12 m-auto">
                <div className="title-center pb-4">
                  <h4>Subscribe for Newsletter</h4>
                  <p>
                    To receive updates related to Insurance Industry, please
                    subscribe to our newsletter.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5 col-md-7 col-sm-12 m-auto p-0">
                <div className="form">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your email"
                        aria-label="Your email"
                        style={{ background: "#a52b46", padding: "2px" }}
                        aria-describedby="basic-addon2"
                        id="basic-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-link"
                          type="button"
                          style={{
                            color: "#fff",
                            textDecoration: "none",
                            marginTop: "-6px",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
