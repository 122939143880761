import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Breadcrumb } from "../components/Breadcrumb";

export const About = () => {
  const options = {
    loop: true,
    margin: 2,
    items: 6,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <Breadcrumb subtitle="Few words" title="About Us" bg="hero-small-about" />

      <div className="section-larger">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="highlight-top">
                <h5>
                  Lorem ipsum dolor sit amet conse ctetur adipisicing elit.
                  Cupiditate, deleniti.
                </h5>
              </div>
            </div>

            <div className="col-lg-7 col-md-7 col-sm-12">
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut
                nesciunt tenetur tempore modi quas error quod quos. Excepturi
                eaque neque sed aperiam. Inventore, velit.
              </p>
              <p>
                Soluta, repellendus! Incidunt delectus nemo distinctio
                temporibus, magni velit ea dignissimos deserunt quidem
                voluptatum non illum fugiat nihil labore pariatur dolor
                perspiciatis in dolore veritatis unde?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-color-grad">
        <div className="section-large reason-sec-back">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-center">
                  <span className="sub-heading-1">We serve</span>
                  <h2>Quality Products</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-12 reason-sec bg-color">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6 pro-border">
              <div className="product">
                <div className="pro-icons">
                  <span className="flaticon-insurance-1"></span>
                </div>
                <h6>Car Insurance</h6>
                <p>100% Security</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 pro-border">
              <div className="product">
                <div className="pro-icons">
                  <span className="flaticon-motorcycle"></span>
                </div>
                <h6>Bike Insurance</h6>
                <p>Both Side</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 pro-border">
              <div className="product">
                <div className="pro-icons">
                  <span className="flaticon-car-1"></span>
                </div>
                <h6>Commercial Use</h6>
                <p>All Type</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 pro-border">
              <div className="product">
                <div className="pro-icons">
                  <span className="flaticon-insurance-3"></span>
                </div>
                <h6>Health Insurance</h6>
                <p>Family</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-first">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center p-0">
                <span className="sub-heading-1">In Simple Steps</span>
                <h2>How Bike insurance works?</h2>
              </div>
            </div>
          </div>
          <OwlCarousel className="owl-theme slid" {...options}>
            <div className="item">
              <div className="row">
                <div className="col-lg-12">
                  <div className="row section">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div className="img-container">
                        <a
                          className="tute-video"
                          href="https://www.youtube.com/watch?v=_8JseXRp43U"
                        >
                          <img src="images/vdo_thumb_1.png" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 offset-lg-1 text-container-right">
                      <div className="container-text">
                        <div className="bike-works">
                          <div className="works-text">
                            <h6>How to claim Insurance?</h6>
                            <p>
                              Lorem ipsum dolor sit, amet consectetur
                              adipisicing elit. Veritatis voluptate minus
                              molestiae earum suscipit dignissimos distinctio
                              laboriosam corporis.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <div className="section-large bg-color">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center p-0">
                <span className="sub-heading-1">Core</span>
                <h2>Fetures</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-6 feture-list mt-2">
              <div className="col-icon-cntr-claim">
                <div className="icon-cntr-claim">
                  <span className="flaticon-document"></span>
                </div>
                <div className="col-text-cntr-claim">
                  <h6>You Register</h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mt-2">
              <div className="col-icon-cntr-claim">
                <div className="icon-cntr-claim">
                  <span className="flaticon-car-4"></span>
                </div>
                <div className="col-text-cntr-claim">
                  <h6>We Pick Up</h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mt-2">
              <div className="col-icon-cntr-claim">
                <div className="icon-cntr-claim">
                  <span className="flaticon-insurance-4"></span>
                </div>
                <div className="col-text-cntr-claim">
                  <h6>You Relax</h6>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mt-2">
              <div className="col-icon-cntr-claim">
                <div className="icon-cntr-claim">
                  <span className="flaticon-car-3"></span>
                </div>
                <div className="col-text-cntr-claim">
                  <h6>We Deliver</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-large">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12">
              <div className="text-center">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="title-center p-0">
                        <span className="sub-heading-1">Our Team</span>
                        <h2>Board Of Directors</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-6 team-list mt-2">
                      <div className="team" data-aos="flip-left">
                        <img
                          src="images/team_img_3.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="team-info">
                          <h5>Ferdinand Karl</h5>
                          <p>CEO &amp; Co-Founder </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 mt-2">
                      <div className="team" data-aos="flip-left">
                        <img
                          src="images/team_img_2.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="team-info">
                          <h5>Kiersten Lange</h5>
                          <p>MD &amp; Co-Founder</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 mt-2">
                      <div className="team" data-aos="flip-left">
                        <img
                          src="images/team_img_1.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="team-info">
                          <h5>Anson Buck</h5>
                          <p>Business Analyst</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-6 mt-2">
                      <div className="team" data-aos="flip-left">
                        <img
                          src="images/team_img_4.jpg"
                          className="img-fluid"
                          alt=""
                        />
                        <div className="team-info">
                          <h5>Aliah Pitts</h5>
                          <p>Senior Manager</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <button type="button" className="btn btn-prim-round mt-4">
                        Read more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
