import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'

const Layout = () => {
  return (
      <>
          <Header />
          <Footer/>
      </>
  )
}

export default Layout