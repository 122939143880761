import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="section-padding-none">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-6 footer-sec">
                <h5>Company</h5>
                <ul className="ml-0">
                  <li>
                    <NavLink to="/about">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact Us </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-sec">
                <h5>General</h5>
                <ul className="ml-0">
                  <li>
                    <NavLink to="/privacy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms">Terms & Conditions</NavLink>
                  </li>
                  <li>
                    <NavLink to="/refund">Refund & Cancellation</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-6 footer-sec">
                <h5>Insurance</h5>
                <ul className="ml-0">
                  <li>
                    <NavLink to="/life">Life Insurance</NavLink>
                  </li>
                  <li>
                    <NavLink to="/health">Health Insurance</NavLink>
                  </li>
                  <li>
                    <NavLink to="/car">Car Insurance Claim</NavLink>
                  </li>
                  <li>
                    <NavLink to="/bike">Bike Insurance Claim</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6 footer-sec">
                <h5>K2VS Insurance Marketing Pvt Ltd</h5>
                <p className="adres">
                  Flat No-15, B Wing Floor 2, Indra Darshan CHSL, Four Bungalows
                  Road, Andheri West Mumbai Maharashtra 400053 India.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid copyright">
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-6">
                  <p>
                    Copyright © 2021. All rights reserved by K2VS Insurance
                    Marketing Pvt Ltd.
                  </p>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-6">
                  <div className="copyright-social">
                    <ul>
                      <li>
                        <a href="#">
                          <img
                            src="images/icon_fb.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="images/icon_tw.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="images/icon_li.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src="images/icon_yt.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
