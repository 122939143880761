import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";

const Contact = () => {
  return (
    <>
      <Breadcrumb
        title="Contact Us"
        subtitle="Any quiery?"
        bg="hero-small-about"
      />

      <div className="section-first">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="contact">
                    <img
                      src="images/send-email-2.png"
                      className="img-fluid"
                      alt=""
                      data-aos="flip-left"
                      data-aos-delay="50"
                    />
                    <span className="lable">Email -</span>
                    <p>principalofficer@insuresahi.in</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6 ">
                  <div className="contact">
                    <img
                      src="images/mobile-phone-2.png"
                      className="img-fluid"
                      alt=""
                      data-aos="flip-left"
                      data-aos-delay="150"
                    />
                    <span className="lable">Call -</span>
                    <p>+91 8976120023</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-6">
                  <div className="contact">
                    <img
                      src="images/mood-happy.png"
                      className="img-fluid"
                      alt=""
                      data-aos="flip-left"
                      data-aos-delay="250"
                    />
                    <span className="lable">Skype -</span>
                    <p>enobel@skype</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="contact">
                    <img
                      src="images/flag.png"
                      className="img-fluid"
                      alt=""
                      data-aos="flip-left"
                      data-aos-delay="350"
                    />
                    <span className="lable">Location -</span>
                    <p>
                      Flat No-15, B Wing Floor 2, Indra Darshan CHSL, Four
                      Bungalows Road, Andheri West Mumbai Maharashtra 400053
                      India.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="form-group">
                <label htmlFor="formGroupExampleInput1">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput1"
                  placeholder="Your name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="email@example.com"
                />
              </div>
              <div className="form-group">
                <label htmlFor="formGroupExampleInput2">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Subject"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Message</label>
                <textarea
                  className="form-control msg-area"
                  id="exampleFormControlTextarea1"
                  placeholder="Type here..."
                  rows="2"
                ></textarea>
              </div>
              <button type="button" className="btn btn-prim-round">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
