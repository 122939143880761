import React from "react";
import { Breadcrumb } from "../components/Breadcrumb";

const Care = () => {
  return (
    <>
      <Breadcrumb
        subtitle="Get Instantly"
        title="Car Insurance"
        bg="hero-small-contact"
      />

      <div className="section-first">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12 text-container-left">
              <div className="container-text">
                <h2>
                  We promise to <br />
                  pay for the car repairs
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic
                  ducimus voluptatibus, et facere ab vel adipisci corporis
                  quidem. Exercitationem similique.
                </p>
                <span className="sub-heading-2">
                  Car insurance is based on the simple concept of risk sharing.
                </span>
                <p>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas provident iste nemo alias distinctio rem esse ex
                  molestiae sapiente explicabo harum quidem sunt architecto
                  nobis amet vel in optio quis cupiditate veniam, nihil
                  nesciunt, soluta labore officiis? Vero, esse nemo.
                </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="img-container">
                <img
                  src="images/about_img_1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center">
                <span className="sub-heading-1">Buy/Renew</span>
                <h2>Insurance Online</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Deleniti numquam sit eligendi veritatis debitis illo
                  consequuntur tenetur distinctio, totam modi!
                </p>
              </div>
            </div>
            <div className="col-lg-8 m-auto">
              <div className="title-center">
                <h4>
                  <strong>How our car insurance works?</strong>
                </h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
                  ex maiores natus voluptas temporibus mollitia.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 steps">
              <div className="img-container-step">
                <img
                  src="images/about_step_1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="step-head vertical-cntr">
                <div className="step-count">
                  <h1>1</h1>
                </div>
                <h6>
                  Start with entering your car number or selecting your car.
                </h6>
              </div>
              <div className="step-text">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Inventore adipisci nihil, eos quia itaque necessitatibus
                  voluptatum cupiditate magnam molestiae. Voluptatibus?
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 steps">
              <div className="img-container-step">
                <img
                  src="images/about_step_2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="step-head vertical-cntr">
                <div className="step-count">
                  <h1>2</h1>
                </div>
                <h6>Just answer a few simple questions about your car.</h6>
              </div>
              <div className="step-text">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Inventore adipisci nihil, eos quia itaque necessitatibus
                  voluptatum cupiditate magnam molestiae. Voluptatibus?
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 steps">
              <div className="img-container-step">
                <img
                  src="images/about_step_3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="step-head vertical-cntr">
                <div className="step-count">
                  <h1>3</h1>
                </div>
                <h6>
                  Amazing online car insurance plans &amp; prices tailor-made
                  for you.
                </h6>
              </div>
              <div className="step-text">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Inventore adipisci nihil, eos quia itaque necessitatibus
                  voluptatum cupiditate magnam molestiae. Voluptatibus?
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 steps">
              <div className="img-container-step">
                <img
                  src="images/about_step_4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="step-head vertical-cntr">
                <div className="step-count">
                  <h1>4</h1>
                </div>
                <h6>
                  Choose a price, make payment &amp; the policy will be in your
                  inbox in seconds.
                </h6>
              </div>
              <div className="step-text">
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Inventore adipisci nihil, eos quia itaque necessitatibus
                  voluptatum cupiditate magnam molestiae. Voluptatibus?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-larger bg-color-10">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="img-container">
                <img src="images/home_img_1.png" className="img-fluid" alt="" />
              </div>
              <div className="text-container">
                <h2>
                  <span className="sub-heading-1">6 Factors Affect</span>
                  Insurance Cost
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius,
                  fugit laudantium? Ad quibusdam blanditiis quas aspernatur.
                </p>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 factors">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="point-list">
                    <ul>
                      <li>
                        <div className="list-icon">
                          <span className="flaticon-credit"></span>
                        </div>
                        <div className="list-text">
                          <h6>Value of your Car</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="list-icon">
                          <span className="flaticon-car-insurance-2"></span>
                        </div>
                        <div className="list-text">
                          <h6>Type of Car Insurance</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                      <li className="last">
                        <div className="list-icon">
                          <span className="flaticon-document"></span>
                        </div>
                        <div className="list-text">
                          <h6>Coverage</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="point-list">
                    <ul>
                      <li>
                        <div className="list-icon">
                          <span className="flaticon-credit"></span>
                        </div>
                        <div className="list-text">
                          <h6>Car’s Age</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="list-icon">
                          <span className="flaticon-car-insurance-2"></span>
                        </div>
                        <div className="list-text">
                          <h6>Extra Features</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="list-icon">
                          <span className="flaticon-document"></span>
                        </div>
                        <div className="list-text">
                          <h6>Your Claim History</h6>
                          <p>
                            Get the best price, every single time. for your Car
                            and Bike
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-larger">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-center">
                <span className="sub-heading-1">Three Atractive</span>
                <h2>Insurance Packages</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="package">
                <h5>Basic</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $70 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li>Damage due to fire </li>
                    <li>Naturaly damage </li>
                    <li>Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
              <div className="package">
                <h5>Standard</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $101 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li className="active">Damage due to fire </li>
                    <li>Naturaly damage </li>
                    <li>Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 no-of-package mt-3">
              <div className="package">
                <h5>Extended</h5>
                <div className="package-list">
                  <div className="duration">
                    <h4>
                      $121 <span>yearly</span>
                    </h4>
                  </div>
                  <ul>
                    <li className="active">Damage third-party vehicle </li>
                    <li className="active">Own Damage Theft </li>
                    <li className="active">Damage due to fire </li>
                    <li className="active">Naturaly damage </li>
                    <li className="active">Personal Accident cover </li>
                  </ul>
                </div>
                <button
                  type="Submit"
                  className="btn btn-prim mt-lg-4 mt-md-3 mt-sm-3"
                >
                  Purchase Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Care;
